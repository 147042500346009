
body {
    font-family: $font-family-default;
    @include font-size(1.4);
}

h1, h2,
.h1, .h2 {
    position: relative;
    font-family: $font-family-theme;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    margin: 0;

    span, em, i {
        display: block;
        font-size: 0.9em;
        font-weight: 100;
    }
}

h1, .h1 {
    @include lineBefore(3px, $color-black);
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
    font-family: $font-family-theme;
    text-transform: none;
    font-weight: 700;
    @include rem('margin', 0 0 10px);
}

h1, .h1 {
    @include font-size(2.6);
}
h2, .h2 {
    @include font-size(2.2);
    @include rem('margin-bottom', 30px);
}
h3, .h3 {
    @include font-size(2.0);
}
h4, .h4 {
    @include font-size(1.6);
}
h5, .h5 {
    @include font-size(1.4);
}
h6, .h6 {
    @include font-size(1.4);
}

a {
    color: $color-dark-gray;

    &:hover {
        color: $color-black;
        text-decoration: none;
    }
}

p, address, ul, ol, blockquote, table, hr {
    @include rem('margin', 0 0 28px);
    
    &:last-child {
        margin: 0;
    }

    & + h2 {
        @include rem('margin-top', 56px);
    }

    & + h3 {
        @include rem('margin-top', 42px);
    }
}

p, address {
    line-height: 1.6;
}

address {
    font-style: normal;
}

ul, ol {
    @include rem('padding', 0 0 0 30px);

    li {
        line-height: 1.6;
        @include rem('margin-bottom', 6px);
        
        &:last-child() {
            margin-bottom: 0;
        }

        ul, ol {
            margin-bottom: 0;
        }
    }
}

blockquote {
    @include rem('padding-left', 30px);
    border-left: 3px solid $color-dark-gray;
    font-style: italic;

    &.alert {
        border-color: $brand-danger;
    }

    &.warning {
        border-color: $brand-warning;
    }
}

.small {
    font-size: 0.8em;
}

input, textarea {
    font-family: $font-family-default;
    @include font-size(1.6);
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    font-family: $font-family-default;
    @include font-size(1.6);
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-family: $font-family-default;
    @include font-size(1.6);
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-family: $font-family-default;
    @include font-size(1.6);
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: $font-family-default;
    @include font-size(1.6);
}

table {

    tr {
        
        td {
            @include rem('padding-right', 30px);
            line-height: 1.4;

            &:last-child() {
                padding-right: 0;
            }
        }
    }
}

hr {
    height: 0;
    border: 0;
}

// Markdown mark-up
.toc {
    @include rem('margin', 60px 0);

    ul {
        list-style: none;
        padding: 0;

        li > ul {
            @include rem('padding', 0 0 0 30px);
        }
    }
}



// Handheld (landscape)
@media (min-width: map-get($grid-breakpoints, sm)) {
    body {
    }
}

// Tablet (portrait)
@media (min-width: map-get($grid-breakpoints, sm)) and (orientation: portrait) {
    body {
    }
}

// Handheld and tablet (portrait)
@media (max-width: map-get($grid-breakpoints, md) - 1) and (orientation: portrait) {
    body {
    }
}

// Desktop and tablet (landscape)
@media (min-width: map-get($grid-breakpoints, md)) {
    body {
        @include font-size(1.6);
    }

    h1, .h1 {
        @include font-size(2.8);
    }
    h2, .h2 {
        @include font-size(2.4);
    }
    h3, .h3 {
        @include font-size(2.2);
    }
    h4, .h4 {
        @include font-size(1.8);
    }
    h5, .h5 {
        @include font-size(1.6);
    }
    h6, .h6 {
        @include font-size(1.6);
    }

    p, address, ul, ol, blockquote, table, hr {
        @include rem('margin', 0 0 32px);

        & + h2 {
            @include rem('margin-top', 64px);
        }

        & + h3 {
            @include rem('margin-top', 48px);
        }
    }
}