
@media only print {

    body {
        justify-content: normal;
        
        // * {
        //     background: none;
        //     color: $color-black;
        // }
        overflow: visible;
        > app {
            overflow: visible;
            > header {
                position: absolute;
                //margin-top: 0;
                margin-left: 1cm;
                height: 0;

                > nav {
                    margin: 0;
                }

                > div,
                > nav > button {
                    display: none;
                }
            }

            > ng-component{

                nav {
                    display: none;
                }

                main {
                    margin: 0 1cm;
                    padding: 0;
                    margin-top: 3.8cm;

                    > section {

                        > div {
                            page-break-inside: avoid;
                            margin-bottom: 28px;
                        }
                    }
                }
            }

            h1:before {
                border-left: solid 3px $color-black;
            }

            h2 {
                @include font-size(1.8);
                line-height: 24px;
            }

            h3, h4 {
                font-family: $font-family-default;
                @include font-size(1.4);
            }

            h1, h2, h3, h4 {
                margin-bottom: 0;
            }

            p, address, ul, ol, blockquote, table, hr {

                & + h2 {
                    @include rem('margin-top', 20px);
                }

                & + h3, & + h4 {
                    @include rem('margin-top', 0px);
                }
            }

        }
    }

    @page {
        margin: 4cm 1cm 3cm;
    }

    @page :first {
        margin: 1.5cm 1cm 2cm;
    }

    header, h1, h2, h3 {
        // prevent page breaks right after a header.
        page-break-after: avoid;
    }

    // img {
    //     // prevent images from bleeding over the edge.
    //     max-width: 100% !important;
    // }
    // article {
    //     page-break-before: always;
    // }

    // ul, img {
    //     page-break-inside: avoid;
    // }

}