

body {

    > app main.split {

        > section {

            &:first-of-type {
                text-align: right;

                h1:before, h2:before, .h1:before, .h2:before {
                    right: -17px;
                    left: initial;
                }
            }
        }
    }
}

// Handheld (landscape)
@media (min-width: map-get($grid-breakpoints, sm)) {
    body {
        > app main.split {

            > section {

                &:first-of-type {
                    @include rem('margin-right', 60px);
                }

                &:last-of-type {
                    @include rem('margin-left', 60px);
                }
            }
        }
    }
}

// Tablet (portrait)
@media (min-width: map-get($grid-breakpoints, sm)) and (orientation: portrait) {
    body {
        > app main.split {

            > section {

                &:first-of-type {
                    @include rem('margin-right', 40px);
                }

                &:last-of-type {
                    @include rem('margin-left', 40px);
                }
            }
        }
    }
}

// Handheld and tablet (portrait)
@media (max-width: map-get($grid-breakpoints, md) - 1) and (orientation: portrait) {
    body {
        > app main.split {

            > section {

                &:first-of-type:not(:only-of-type) {
                    @include rem('margin-bottom', 20px);
                }

                &:last-of-type:not(:only-of-type) {
                    @include rem('margin-top', 20px);
                }
            }
        }
    }
}

// Desktop and tablet (landscape)
@media (min-width: map-get($grid-breakpoints, md)) {
    body {
        > app main.split {
        }
    }
}