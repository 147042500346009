
body {
    
    > app {

        main.right {

            > section {
            }
        }
    }
}

// Handheld (landscape)
@media (min-width: map-get($grid-breakpoints, sm)) {
    body {
        > app main.right {

            > section {
                text-align: right;
                margin-right: 25%;

                h1:before, h2:before, .h1:before, .h2:before {
                    right: -17px;
                    left: initial;
                }
            }
        }
    }
}

// Tablet (portrait)
@media (min-width: map-get($grid-breakpoints, sm)) and (orientation: portrait) {
    body {
        > app main.right {
        }
    }
}

// Handheld and tablet (portrait)
@media (max-width: map-get($grid-breakpoints, md) - 1) and (orientation: portrait) {
    body {
        > app main.right {
        }
    }
}

// Desktop and tablet (landscape)
@media (min-width: map-get($grid-breakpoints, md)) {

    body {
        > app main.right {
        }
    }
 }