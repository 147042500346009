
@import 'breakpoints';

/* sample: @include rem('margin', 20px 10px) */
@mixin rem($attr, $px-values) {
    $baseline-rem: 10px / 1rem * 1;
    #{$attr}: $px-values;

    $rem-values: ();

    @each $value in $px-values {
        @if $value == 0 or type-of( $value ) != "number" {
            $rem-values: append($rem-values, $value);
        } @else {
            $rem-values: append($rem-values, $value / $baseline-rem);
        }
    }

    #{$attr}: $rem-values;
}

/* sample: @include font-size(2.5) */
@mixin font-size($size) {
    $baseline-rem: 10px / 1rem * 1;
    $px-size: 10px*$size;
    font-size: $px-size;
    font-size: $px-size / $baseline-rem
}

/* Opacity should be on a 100 point scale (0-100 instead of 0.0-1.0)
 This should be used to supplement a normal border definition as it
 only deals with the 'border-color' property.*/
@mixin opacity($attr, $color, $opacity) {
    $opacity: $opacity / 100;

    #{$attr}: $color;
    #{$attr}: rgba($color, $opacity);
}

@mixin lineBefore ($thickness, $color) {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -17px;
        background-color: $color;
        width: $thickness;
        height: 100%;
    }
}