
body {

    &.mobile-fix {

        > app {

            > ng-component{

                nav {

                    > ul {

                        > li.down {
                            margin-top: -50px;
                        }
                    }
                }
            }
        }
    }

    > app {

        > ng-component{

            nav {
                position: fixed;
                z-index: $zindex-arrows;
                top: 0;
                left: 0;

                > ul {
                    position: absolute;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    > li {
                        position: absolute;

                        &.up {
                            left: 50%;
                            left: 50vw;
                            transform: translateX(-50%);

                            > a {
                                @include rem('margin-top', 30px);
                                border-top: solid $color-black 2px;

                                &:after, &:before {
                                    bottom: 15px;
                                    left: 50%;
                                    border: solid transparent;
                                    content: " ";
                                    height: 0;
                                    width: 0;
                                    position: absolute;
                                    pointer-events: none;
                                }

                                &:after {
                                    border-bottom-color: $color-gray;
                                    border-width: 14px;
                                    margin-left: -14px;
                                }
                                &:before {
                                    border-bottom-color: $color-black;
                                    border-width: 16px;
                                    margin-left: -16px;
                                }
                            }                        
                        }

                        &.right {
                            top: 50%;
                            top: 50vh;
                            left:100%;
                            left: 100vw;
                            transform: translate(-100%, -50%);

                            > a {
                                @include rem('margin-right', 15px);
                                border-right: solid $color-black 2px;

                                &:after, &:before {
                                    left: 15px;
                                    top: 50%;
                                    border: solid transparent;
                                    content: " ";
                                    height: 0;
                                    width: 0;
                                    position: absolute;
                                    pointer-events: none;
                                }

                                &:after {
                                    border-left-color: $color-gray;
                                    border-width: 14px;
                                    margin-top: -14px;
                                }
                                &:before {
                                    border-left-color: $color-black;
                                    border-width: 16px;
                                    margin-top: -16px;
                                }
                            }
                        }

                        &.down {
                            top: 100%;
                            top: 100vh;
                            left: 50%;
                            left: 50vw;
                            transform: translate(-50%, -100%);

                            > a {
                                @include rem('margin-bottom', 30px);
                                border-bottom: solid $color-black 2px;

                                &:after, &:before {
                                    top: 15px;
                                    left: 50%;
                                    border: solid transparent;
                                    content: " ";
                                    height: 0;
                                    width: 0;
                                    position: absolute;
                                    pointer-events: none;
                                }

                                &:after {
                                    border-top-color: $color-gray;
                                    border-width: 14px;
                                    margin-left: -14px;
                                }
                                &:before {
                                    border-top-color: $color-black;
                                    border-width: 16px;
                                    margin-left: -16px;
                                }
                            }
                        }

                        &.left {
                            top: 50vh;
                            transform: translateY(-50%);

                            > a {
                                @include rem('margin-left', 15px);
                                border-left: solid $color-black 2px;
                                
                                &:after, &:before {
                                    right: 15px;
                                    top: 50%;
                                    border: solid transparent;
                                    content: " ";
                                    height: 0;
                                    width: 0;
                                    position: absolute;
                                    pointer-events: none;
                                }

                                &:after {
                                    border-right-color: $color-gray;
                                    border-width: 14px;
                                    margin-top: -14px;
                                }
                                &:before {
                                    border-right-color: $color-black;
                                    border-width: 16px;
                                    margin-top: -16px;
                                }
                            }                        
                        }

                        > a {
                            position: relative;
                            display: block;
                            width: 36px;
                            height: 36px;

                            > span {
                                position: absolute;
                                width: 1px;
                                height: 1px;
                                padding: 0;
                                margin: -1px;
                                overflow: hidden;
                                clip: rect(0, 0, 0, 0);
                                border: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}