
p + form {
    @include rem('padding-top', 16px);
}

form {
    position: relative;
    @include rem('margin', 32px 0);
    min-width: 100%;

    > div {
        @include rem('margin-bottom', 8px);

        & + button {
            @include rem('margin-top', 10px);
        }
    }

    label {
        display: none;
    }

    input[type="text"], input[type="email"], 
    input[type="number"], input[type="password"], textarea {
        display: inline-block;
        width: 100%;
        border: 0;
        border-bottom: 1px solid $color-dark-gray;
        margin-bottom: 1px;
        background-color: transparent;
        @include rem('padding', 6px 0);
        outline: none;
        line-height: 1.5;

        &:required {
            border-bottom: 1px solid $color-black;
        }

        &.ng-invalid.ng-touched {
            color: $color-red;
            border-bottom: 2px solid $color-red;
            margin-bottom: 0;
        }

        &:focus {
            color: $color-black;
            border-bottom-width: 2px;
            margin-bottom: 0;
        }
    }

    textarea {
        height: 24px;
        transition: height .3s;

        &:focus, &.ng-touched {
            height: 120px;
        }
    }

    button {
        position: relative;
        border: 0;
        background-color: transparent;
        @include rem('padding', 0 22px 0 0);
        @include rem('margin', 8px 0);
        border-right: 2px solid $color-black;
        font-family: $font-family-theme;
        font-weight: 300;
        font-size: 18px;
        text-transform: uppercase;
        text-align: left;
        outline: none;

        &:focus {
            outline: none;
        }

        &:after, &:before {
            right: -8px;
            top: 50%;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        &:before {
            border-left-color: $color-black;
            border-width: 10px;
            margin-top: -10px;
        }
        &:after {
            border-left-color: $color-gray;
            border-width: 12px;
            margin-top: -12px;
        }

        &:hover {
            color: $color-dark-gray;
            border-right: 2px solid $color-dark-gray;
            cursor: pointer;

            &:before {
                border-left-color: $color-dark-gray;
            }
        }

        &:disabled {
            color: $color-dark-gray;
            border-right-color: $color-dark-gray;


            &:before {
                border-left-color: $color-dark-gray;
            }
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow:0 0 0 50px $color-gray inset; /* Change the color to your own background color */
        -webkit-text-fill-color: $color-black;
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: $color-dark-gray;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $color-dark-gray;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $color-dark-gray;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $color-dark-gray;
    }

    input:invalid, textarea:invalid {
        box-shadow: none;
    }

    .message {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include opacity('background-color', $color-gray, 80);
        @include font-size(2.0);

        &.hidden {
            display: none;
        }

        > div {
            top: 50%;
            position: absolute;
            display: block;
            text-align: center;
            width: 100%;
            transform: translateY(-50%);

            > p {
                @include rem('margin-bottom', 16px);
            }
    
            > a {
                text-align: center;
                color: $color-black;
                border-width: 1px;
                border-style: solid;
                border-color: $color-dark-gray;
                padding: 5px 16px;
                font-family: "Mesmerize","Source Sans Pro",sans-serif;
                font-weight: 300;
                font-size: 16px;
                text-transform: uppercase;
                cursor: pointer;

                &:hover {
                    border-color: $color-black;
                }
       
                &.hidden {
                    display: none;
                }
            }
        }
    }
}



// Handheld (landscape)
@media (min-width: map-get($grid-breakpoints, sm)) {

    form {
        min-width: 300px;

        > div {
            @include rem('margin-bottom', 8px);

            // & + button {
            //     @include rem('margin-top', 30px);
            // }
        }
    }
}

// Tablet (portrait)
@media (min-width: map-get($grid-breakpoints, sm)) and (orientation: portrait) {
    body {
        > app > main {
        }
    }
}

// Handheld and tablet (portrait)
@media (max-width: map-get($grid-breakpoints, md) - 1) and (orientation: portrait) {
    body {
        > app > main {
            flex-direction: column;
        }
    }
}

// Desktop and tablet (landscape)
@media (min-width: map-get($grid-breakpoints, md)) {

    body {
        > app > main {
            flex-direction: row;

            > header {

                h1 {
                    @include font-size(3.6);
                }
            }
        }
    }
 }