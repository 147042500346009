
body {

    > app {

        footer {
            position:fixed;
            bottom: 0;
            width: 100vw;

            // /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eaeaea+0,eaeaea+100&0+0,1+40 */
            // background: -moz-linear-gradient(top, rgba(234,234,234,0) 0%, rgba(234,234,234,1) 40%, rgba(234,234,234,1) 100%); /* FF3.6-15 */
            // background: -webkit-linear-gradient(top, rgba(234,234,234,0) 0%,rgba(234,234,234,1) 40%,rgba(234,234,234,1) 100%); /* Chrome10-25,Safari5.1-6 */
            // background: linear-gradient(to bottom, rgba(234,234,234,0) 0%,rgba(234,234,234,1) 40%,rgba(234,234,234,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

            > nav,
            > div {
                @include rem('margin', 20px);

                ul {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    height: 40px;

                    li {
                        height: 12px;
                        width: 12px;
                        float: left;
                        @include rem('margin', 0 15px);
                        @include rem('padding', 8px);

                        a {
                            color: $color-black;
                            line-height: 1;
                            @include font-size(2);
                        }
                    }
                }
            }

            &.right {

                > nav,
                > div {
                    
                    ul {
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}