
body {

    > app {

        > header {
            position:fixed;
            top: 0;
            width: 100vw;
            z-index: $zindex-navbar;

            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eaeaea+0,eaeaea+100&1+60,0+100 */
            background: -moz-linear-gradient(top, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 60%, rgba(234,234,234,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(234,234,234,1) 0%,rgba(234,234,234,1) 60%,rgba(234,234,234,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(234,234,234,1) 0%,rgba(234,234,234,1) 60%,rgba(234,234,234,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

            > nav {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                @include rem('margin', 20px);

                > button {
                    height: 48px;
                    width: 48px;
                    border: 0;
                    background-color: transparent;
                    margin: 0;
                    @include rem('padding', 0 4px);
                    outline: 0;

                    span {
                        display: block;
                        width: 100%;
                        height: 8px;
                        border-top: 2px $color-black solid;
                        border-bottom: 2px $color-black solid;
                        @include rem('margin', 8px 0);
                    }

                    &:hover {
                        cursor: pointer;

                        span {
                            border-color: $color-dark-gray;
                        }
                    }

                    :focus {
                        outline: 0;
                    }
                }

                > a {
                    font-family: $font-family-theme;
                    @include font-size(1.7);
                    line-height: 1;
                    font-weight: 700;
                    border: 2px solid $color-black;
                    padding: 5px 0;
                    width: 48px;
                    height: 48px;
                    color: $color-black;
                    text-decoration: none;
                    text-align: center;
                    box-sizing: border-box;
                    outline: none;

                    &:hover {
                        border-color: $color-dark-gray;
                        color: $color-dark-gray;
                    }

                    span {
                        display: block;
                    }
                }

            }

            > div {
                display: block;
                width: 100%;
                text-align: right;
                height: 0;
                overflow: hidden;
                -webkit-transition: height .3s; /* Safari */
                transition: height .3s;

                &.open {
                    height: 100px;
                }

                > ul {
                    list-style: none;
                    margin: 0;
                    @include rem('padding', 0 20px);
                    background-color: $color-gray;

                    li {
                        text-transform: uppercase;
                        @include rem('margin-bottom', 20px);

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            @include font-size(2);
                            color: $color-black;
                            text-decoration: none;

                            &:hover {
                                color: $color-dark-gray;
                            }
                        }
                    }
                }
                
                > div {
                    width: 100%;
                    height: 40px;

                    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eaeaea+0,eaeaea+100&1+60,0+100 */
                    background: -moz-linear-gradient(top, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 60%, rgba(234,234,234,0) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(234,234,234,1) 0%,rgba(234,234,234,1) 60%,rgba(234,234,234,0) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(234,234,234,1) 0%,rgba(234,234,234,1) 60%,rgba(234,234,234,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                }
            }
        }
    }
}

// Handheld (landscape)
@media (min-width: map-get($grid-breakpoints, sm)) and (orientation: landscape) {
    body {
        > app > header {
        }
    }
}

// Handheld and tablet (portrait)
@media (max-width: map-get($grid-breakpoints, md)) and (orientation: portrait) {
    body {
        > app > header {
        }
    }
}

// Desktop and tablet (landscape)
@media (min-width: map-get($grid-breakpoints, md)) {
    body {
        > app > header {
            
            > nav {
                @include rem('margin', 40px 40px 20px 40px);
            }
            
            > div > ul {
                //@include rem('margin', 0 40px);
                @include rem('padding', 20px 40px);
            }
        }
    }
}