html {
    // set root value for better REM handling
    font-size: 62.5%; /* 62.5% of 16px = 10px */
}

body {
    &::after {
        content: 'mobile';
        display: none;
    }
}

// Handheld (landscape)
@media (min-width: map-get($grid-breakpoints, sm)) and (orientation: landscape) {
    body {
        &::after {
            content: 'handheld-landscape';
            display: none;
        }
    }
}

// Handheld and tablet (portrait)
@media (max-width: map-get($grid-breakpoints, md)) and (orientation: portrait) {
    body {
        &::after {
            content: 'handheld-portrait';
            display: none;
        }
    }
}

// Desktop and tablet (landscape)
@media (min-width: map-get($grid-breakpoints, md)) {
    body {
        &::after {
            content: 'desktop';
            display: none;
        }
    }
 }