
// ------- Z-INDEXES
// $zindex-dropdown-backdrop:  990 !default;
$zindex-navbar:            1000 !default;
// $zindex-dropdown:          1000 !default;
// $zindex-fixed:             1030 !default;
// $zindex-sticky:            1030 !default;
// $zindex-modal-backdrop:    1040 !default;
// $zindex-modal:             1050 !default;
// $zindex-popover:           1060 !default;
// $zindex-tooltip:           1070 !default;
$zindex-arrows:     1080;


// ------- COLORS
$color-black:     #000000;
$color-dark-gray: #666666;
$color-gray:      #eaeaea;
$color-red:       #b22222;   // firebrick

$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;


// ------- TYPOGRAPHY
@font-face {
    font-family: 'Mesmerize';
    src: url('../fonts/mesmerize-bd.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Mesmerize';
    src: url('../fonts/mesmerize-lt.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url('../fonts/sourcesanspro-regular.woff2') format('woff2'),
         url('../fonts/sourcesanspro-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-theme: "Mesmerize", "Source Sans Pro", sans-serif;
$font-family-default: $font-family-sans-serif;


// ------- BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;