
html, body {
    height: 100%;
}

body {
    display:flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-gray;

    &.flex-start {
        justify-content: flex-start;
    }

    > app {

        ng-component {
            display: block;
        }

        main {
            margin: 0;
            @include rem('padding', 100px 40px);
            display:flex;
            flex-direction: column;
            justify-content: center;

            > section.home {
                width: auto;
                display: inherit;
                
                > header {
                    margin: 0 auto;
                }
            }

            > section {

                > *:last-child {
                    margin-bottom: 0;
                }

                > header {
                    @include rem('margin-bottom', 20px);
                }
            }
        }
    }
}

// Handheld (landscape)
@media (min-width: map-get($grid-breakpoints, sm)) {
    body {
        > app main {
            @include rem('padding', 100px 60px);
            flex-direction: row;
        }
    }
}

// Tablet (portrait)
@media (min-width: map-get($grid-breakpoints, sm)) and (orientation: portrait) {
    body {
        > app main {
            @include rem('padding', 100px 60px);
        }
    }
}

// Handheld and tablet (portrait)
@media (max-width: map-get($grid-breakpoints, md) - 1) and (orientation: portrait) {
    body {
        > app main {
            @include rem('padding', 100px 55px);
            flex-direction: column;
        }
    }
}

// Desktop and tablet (landscape)
@media (min-width: map-get($grid-breakpoints, md)) {

    body {
        > app main {
            @include rem('padding', 100px 60px);
            flex-direction: row;

            > section.home {

                > header {

                    h1 {
                        @include font-size(3.6);
                    }
                }
            }

            > section {
                width: 600px;
            }
        }
    }
}

// Desktop and tablet (landscape)
@media (min-width: map-get($grid-breakpoints, lg)) {

    body {
        > app main {
            flex-direction: row;

            > section.home {

                > header {

                    h1 {
                        @include font-size(3.6);
                    }
                }
            }

            > section {
                width: 850px;
            }
        }
    }
}

// Desktop and tablet (landscape)
@media (min-width: map-get($grid-breakpoints, xl)) {

    body {
        > app main {

            > section {
                width: 1024px;
            }
        }
    }
}