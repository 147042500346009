
body {

    > app {

        main {

            &:before {
                content: '';
                background-size: contain;
                background-repeat: no-repeat;
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: -1;
            }

            &.left:before {
                background-image: url('/images/consultancy.png');
                background-position: bottom left;
            }

            &.right:before {
                background-image: url('/images/development.png');
                background-position: bottom right;
            }

            > section.login {
                width: auto;
            }
        }
    }
}